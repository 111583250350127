<template>
  <div
    class="hotel-main"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div>
      <div class="d-flex justify-content select-div" style="padding: 10px">
        <div class="ml-10 mr-10 d-flex align-items-center">
          <p class="mr-5" style="width: 70px; font-size: 14px">住户:</p>
          <el-input
            placeholder="请输入住户"
            prefix-icon="el-icon-search"
            v-model="name"
          >
          </el-input>
        </div>
        <div class="ml-10 mr-10 d-flex align-items-center">
          <p class="mr-5" style="width: 70px; font-size: 14px">手机号:</p>
          <el-input
            placeholder="请输入昵称"
            prefix-icon="el-icon-search"
            v-model="phone"
          >
          </el-input>
        </div>
        <div class="ml-10 mr-10 d-flex align-items-center">
          <p class="mr-5" style="width: 120px; font-size: 14px">
            预定入住开始时间:
          </p>
          <el-date-picker
            value-format="timestamp"
            v-model="startTime"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <div class="ml-10 mr-10 d-flex align-items-center">
          <p class="mr-5" style="width: 120px; font-size: 14px">
            预定入住结束时间:
          </p>
          <el-date-picker
            value-format="timestamp"
            v-model="endTime"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <el-button
          @click="getUserList"
          size="small"
          icon="el-icon-search"
          type="primary"
          >查询</el-button
        >
      </div>
      <!-- <div class="d-flex justify-content-end" style="padding: 10px;">
        <el-button type="primary" style="width: 100px;" @click="addUser">添加</el-button>
      </div> -->
      <el-table
        stripe
        size="small "
        :data="hotelData"
        style="width: 100%"
        border
      >
        <el-table-column type="index" label="序号" width="60" align="center">
        </el-table-column>
        <el-table-column prop="name" label="住户" width="120">
        </el-table-column>
        <el-table-column prop="roomTypeName" label="房间类型" width="120">
        </el-table-column>
        <el-table-column prop="roomName" label="房间号" width="200">
        </el-table-column>
        <el-table-column prop="phone" width="200" label="电话">
        </el-table-column>
        <el-table-column
          prop="estimatedCheckIn"
          width="200"
          label="预定开始时间"
        >
        </el-table-column>
        <el-table-column
          prop="estimatedCheckOut"
          width="200"
          label="预定结束时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          width="200"
          label="是否入住"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 0">否</el-tag>
            <el-tag v-if="scope.row.status == 1">是</el-tag>
            <el-tag v-if="scope.row.status == 2">已取消</el-tag>
            <el-tag v-if="scope.row.status == 3">已退房</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="400" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status !== 3"
              size="mini"
              type="primary"
              @click="fkFn(scope.$index, scope.row)"
              >发卡
            </el-button>
            <el-button size="mini" @click="handleCheck(scope.$index, scope.row)"
              >查看
            </el-button>
            <el-button
              v-if="scope.row.status == 0"
              size="mini"
              type="primary"
              @click="yyFn(scope.$index, scope.row)"
              >登记入住
            </el-button>

            <el-button
              size="mini"
              type="danger"
              v-if="scope.row.status == 0"
              @click="handleDelete(scope.$index, scope.row)"
              >取消预约
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      class="mb-15"
      background
      @current-change="handleCurrentChange"
      @prev-click="prevPage"
      @next-click="nextPage"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>

    <!-- 弹出框 -->
    <el-dialog
      title="预约入住"
      :visible.sync="ydrz"
      width="570px"
      :before-close="closeydrz"
    >
      <div class="content">
        <div class="d-flex align-items-center mb-15">
          <p class="w-100 text-left">姓名:</p>
          <el-input
            style="width: 350px"
            placeholder="请输入姓名"
            v-model="idname"
            :disabled="nameshow"
            @input="getuserinfo(idname)"
          >
          </el-input>
        </div>
        <div class="d-flex align-items-center mb-15">
          <p class="w-100 text-left">身份证号:</p>
          <el-input
            style="width: 350px"
            placeholder="请输入身份证号"
            v-model="idCard"
            @input="getuserinfo(idCard)"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeydrz">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 弹出框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="570px">
      <div class="content">
        <div class="d-flex align-items-center mb-15">
          <p class="w-100 text-left">用户:</p>
          <el-input
            style="width: 350px"
            placeholder="请输入用户名"
            v-model="form.name"
            :disabled="userValue == 'check'"
            clearable
          >
          </el-input>
        </div>
        <div class="d-flex align-items-center mb-15">
          <p class="w-100 text-left">手机号:</p>
          <el-input
            style="width: 350px"
            placeholder="请输入手机号"
            v-model="form.phone"
            :disabled="userValue == 'check'"
            clearable
          >
          </el-input>
        </div>
        <div class="d-flex align-items-center mb-15">
          <p class="w-100 text-left">房间类型:</p>
          <el-input
            style="width: 350px"
            placeholder="请输入昵称"
            v-model="form.roomTypeName"
            :disabled="userValue == 'check'"
            clearable
          >
          </el-input>
        </div>
        <div class="d-flex align-items-center mb-15">
          <p class="w-100 text-left">房间号:</p>
          <el-input
            style="width: 350px"
            placeholder="请输入用户密码"
            v-model="form.roomName"
            :disabled="userValue == 'check'"
            clearable
          >
          </el-input>
        </div>
        <div class="d-flex align-items-center mb-15">
          <p class="w-100 text-left">是否入住:</p>
          <el-input
            style="width: 350px"
            placeholder="请输入用户密码"
            v-model="form.statusName"
            :disabled="userValue == 'check'"
            clearable
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!-- <el-button type="primary" @click="confirm">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from "../utils/request";

export default {
  name: "HotelManage",
  data() {
    return {
      loading: false,
      name: "",
      hotelData: [],
      title: "",
      page: "",
      userValue: "",
      deptId: "",
      pageNum: 0,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      ydrz: false,
      userList: [],
      phone: "",
      endTime: "",
      startTime: "",
      nameshow: false,
      tempPassword: undefined,
      form: {
        id: "",
        name: "",
        roomName: "",
        roomTypeName: "",
        phone: "",
        status: 0,
      },
      jdId: "",
      idCard: "",
      idname: "",
    };
  },
  filters: {
    mapFormat(val, map) {
      console.log(val);
      console.log(map);
      return map[val];
    },
  },
  methods: {
    //获取用户列表
    getUserList() {
      (this.pageNum = 0),
        (this.pageSize = 10),
        //获取用户列表
        this.userListRequest();
    },

    //获取用户列表请求
    userListRequest() {
      get("/api/xinneng-booking/initList", {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        endTime: this.endTime,
        name: this.name,
        phone: this.phone,
        startTime: this.startTime,
      })
        .then((res) => {
          console.log(res);
          this.hotelData = res.data.data.tlist;
          this.total = res.data.data.total;
          this.pageNum = res.data.data.current;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    yyFn(index, row) {
      this.ydrz = true;
      this.jdId = row.id;
    },
    closeydrz() {
      this.idCard = "";
      this.idname = "";
      this.ydrz = false;
      this.nameshow = false;
    },
    getuserinfo(data) {
      var name = data.split("'");
      this.idname = name[0];
      if (this.idname != "") {
        this.nameshow = true;
      } else {
        this.nameshow = false;
      }
      console.log(name);
      this.idCard = name[1];
    },
    sub() {
      if (!this.idCard) {
        this.$message({
          message: "请填写卡片",
          type: "warning",
          duration: 1000,
        });
        return false;
      }

      post("/api/xinneng-booking/checkIn/" + this.jdId + "/" + this.idCard)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "入住成功",
              type: "success",
              duration: 1000,
            });
            setTimeout(() => {
              this.$router.go(0);
            }, 1000);
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
              duration: 1000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fkFn(index, row) {
      this.$alert("请将卡片在读卡器", "注意", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.loading = true;
          post("/api/xinneng-booking/guestCard/" + row.id).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "发卡成功",
                type: "success",
              });
              this.loading = false;
            } else if (res.data.code !== 200) {
              this.$message.error("发卡失败");
              this.loading = false;
            }
          });
        },
      });
    },
    //删除用户方法
    handleDelete(index, row) {
      this.$confirm("是否取消?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        post("/api/xinneng-booking/cancel/" + row.id)
          .then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "取消成功",
                type: "success",
                duration: 1000,
              });
              setTimeout(() => {
                this.$router.go(0);
              }, 1000);
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
                duration: 1000,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    //增加用户方法
    addUser() {
      this.form = {};
      this.dialogVisible = true;
      this.title = "添加用户";
      this.userValue = "add";
      this.getUserList();
    },

    handleCheck(index, row) {
      this.userValue = "check";
      this.dialogVisible = true;
      this.title = "查看预定信息";
      this.form = row;
      if (row.status == 0) {
        this.form.statusName = "否";
      } else if (row.status == 1) {
        this.form.statusName = "是";
      } else if (row.status == 2) {
        this.form.statusName = "已取消";
      }
    },

    //修改用户信息
    handleModify(index, row) {
      this.form = row;
      this.tempPassword = this.form.password;
      this.dialogVisible = true;
      this.title = "修改用户信息";
      this.userValue = "modify";
    },

    //
    confirm() {
      let value = this.userValue;

      if (value == "add") {
        this.userRequest(value, data);
      } else if (value == "modify") {
        data.id = this.form.id;
        if (this.tempPassword == this.form.password) {
          this.form.password = undefined;
        }
        // data.deptId = this.deptId
        this.userRequest(value, data);
      }
    },

    //请求方法
    userRequest(url, data) {
      post("/api/user/" + url, data)
        .then((res) => {
          console.log(res);
          if (res.data.code === "200") {
            this.$message({
              message: "操作成功！",
              type: "success",
              duration: 1000,
            });
            this.$router.go(0);
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
              duration: 1000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //选择页码
    handleCurrentChange(num) {
      this.pageNum = num;
      this.userListRequest();
    },

    //上一页
    prevPage(num) {
      this.pageNum = num;
      this.userListRequest();
    },

    //下一页
    nextPage(num) {
      this.pageNum = num;
      this.userListRequest();
    },
  },
  created() {},
  mounted() {
    this.getUserList();
  },
};
</script>

<style scoped>
.hotel-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 30px 0px rgba(22, 115, 255, 0.1);
}
.select-div {
  display: flex;
  justify-content: right;
}
</style>
