var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"depart-main"},[_c('div',{staticClass:"d-flex justify-content-end mb-10",staticStyle:{"padding":"10px"}},[_c('el-button',{staticStyle:{"width":"100px"},attrs:{"type":"primary"},on:{"click":_vm.addDepart}},[_vm._v("添加")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.departData,"row-key":"id","border":"","tree-props":{ hasChildren: 'hasChildren',children: 'children',}}},[_c('el-table-column',{attrs:{"prop":"name","label":"部门","width":"300"}}),_c('el-table-column',{attrs:{"label":"状态","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status==1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("正常")]):_vm._e(),(scope.row.status==0)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("停用")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.handleModify(scope.$index, scope.row)}}},[_vm._v("编辑 ")]),_c('el-button',{attrs:{"disabled":scope.row.children.length !== 0,"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}}},[_vm._v("删除 ")])]}}])})],1),_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"width":"570px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"contont"},[_c('div',{staticClass:"d-flex align-items-center mb-15"},[_c('p',{staticClass:"w-100 text-left"},[_vm._v("父部门:")]),_c('el-cascader',{ref:"cascader",staticStyle:{"width":"350px"},attrs:{"options":_vm.deptList,"props":{
                       checkStrictly: true,
                       expandTrigger: 'hover',
                       emitPath: false,
                       value: 'id',
                       label: 'name'
                     }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var node = ref.node;
                     var data = ref.data;
return [_c('div',{on:{"click":function($event){return _vm.cascaderClick(data)}}},[_c('span',[_vm._v(_vm._s(data.name))]),(!node.isLeaf)?_c('span',[_vm._v(" ("+_vm._s(data.children.length)+") ")]):_vm._e()])]}}]),model:{value:(_vm.form.pId),callback:function ($$v) {_vm.$set(_vm.form, "pId", $$v)},expression:"form.pId"}})],1),_c('div',{staticClass:"d-flex align-items-center mb-15"},[_c('p',{staticClass:"w-100 text-left"},[_vm._v("部门名称:")]),_c('el-input',{staticStyle:{"width":"350px"},attrs:{"placeholder":"请输入部门名称","clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"d-flex align-items-center mb-15"},[_c('p',{staticClass:"w-100 text-left"},[_vm._v("权限:")]),_c('el-select',{staticStyle:{"width":"350px"},attrs:{"placeholder":"请选择"},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}},_vm._l((_vm.roleOptions),function(item){return _c('el-option',{attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"w-100 text-left"},[_vm._v("状态:")]),_c('div',[_c('el-radio',{attrs:{"label":"1"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v("正常")]),_c('el-radio',{attrs:{"label":"0"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v("停用")])],1)])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }